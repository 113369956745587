// Color Scheme
$color-rich-black: #141516;
$color-off-black: #1d1d20;
$color-rich-white: #f2f4f3;
$color-off-white: #ecebe7;

$color-light-blue: #4bdfff;
$color-sapphire-blue: #1b3c7a;
$color-vivid-sky-blue: #06bee1;
$color-vivid-sky-blue-hover: #105a69;
$color-light-blue: #4bdfff;
$color-midnight: #631d76;
