@font-face {
  font-family: "Arca Major";
  src: url("../Assets/Fonts/ArcaMajorBold.woff2") format("woff2"),
    url("../Assets/Fonts/ArcaMajorBold.woff") format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Arca Major Heavy";
  src: url("../Assets/Fonts/ArcaMajorHeavy.woff2") format("woff2"),
    url("../Assets/Fonts/ArcaMajorHeavy.woff") format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Raleway Italic";
  src: url("../Assets/Fonts/RalewayItalic.woff2") format("woff2"),
    url("../Assets/Fonts/RalewayItalic.woff") format("woff"); /* Modern Browsers */
}

@font-face {
  font-family: "Raleway";
  src: url("../Assets/Fonts/Raleway.woff2") format("woff2"),
    url("../Assets/Fonts/Raleway.woff") format("woff"); /* Modern Browsers */
}
