@import "Styles/colors.scss";

.drawer-button {
  position: absolute;
  z-index: 3;
}

.sidebar {
  display: flex;
}

.sidebar-content {
  color: $color-rich-white;
  margin: 30px;
  margin-top: 50px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;

  .sidebar-footer {
    margin-top: auto;
    text-align: center;
    font-size: 15px;
    padding-top: 20px;

    .sidebar-footer-links {
      display: flex;
      a {
        margin: auto;
        color: white;
        :hover {
          color: rgba(255, 255, 255, 0.8);
        }
        * {
          font-size: 2.5rem;
        }
      }
    }
  }

  .logo-header {
    display: flex;
    flex-direction: row;
  }

  .logo-image {
    height: 64px;
    padding-right: 15px;
  }

  h2 {
    font-size: 25px;
    font-family: "Arca Major Heavy";
    margin: 2px 0;
  }
  a {
    font-size: 25px;
    font-family: "Arca Major";
  }

  .linebreak {
    width: 100%;
  }

  .links {
    display: flex;
    flex-direction: column;
    svg {
      font-size: 28px;
      vertical-align: sub;
    }

    a {
      margin: 12px;
      color: $color-rich-white;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    :first-child {
      padding-top: 0;
    }
  }
}
