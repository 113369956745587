@import "Styles/colors.scss";
@import "Styles/mediaQueries.scss";

.section-heading {
  font-size: 1.6em;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
}

.experience-container {
  background-color: $color-rich-black;
  font-family: "Raleway", sans-serif;
  padding: 0 60px;
  padding-bottom: 50px;
  @include sm() {
    padding: 0 20px;
    padding-bottom: 50px;
  }
  flex-wrap: wrap;

  .experience-cont {
    & > div:not(:first-child) {
      margin-top: 24px;
    }
    margin-bottom: 50px;
  }

  .experience-title {
    text-align: left;
    font-size: 1.4em;
    margin: 10px 0;
    color: $color-off-white;
  }

  .experience-div {
    width: 100%;

    .experience-year {
      white-space: nowrap;
      text-align: left;
      font-size: 0.8em;
      font-weight: 300;
      margin: 10px 0;
      margin-right: 20px;
      color: $color-off-white;
    }
    .experience-program {
      text-align: left;
      font-size: 0.8em;
      font-weight: medium;
      margin: 10px 0;
      color: $color-off-white;
    }
    .experience-content {
      text-align: left;
      font-size: 0.8em;
      font-weight: 300;
      margin: 10px 0;
      color: $color-off-white;
      white-space: pre-wrap;
      li:not(:first-child) {
        padding-top: 6px;
      }
    }
    .experience-link {
      text-align: left;
      font-size: 0.8em;
      font-weight: 300;
      margin: 10px 0;
      color: $color-vivid-sky-blue;
    }
  }
  .experience-first {
    flex: 1;
  }

  .experience-subheading {
    text-align: left;
    font-size: 28px;
    margin: 0;
    margin-top: 24px;
    color: $color-vivid-sky-blue;
  }

  .experience-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
