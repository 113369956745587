@import "Styles/colors.scss";
@import "Styles/mediaQueries.scss";

.section-heading {
  font-size: 1.6em;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
  text-align: center;
}

.projects-container {
  background-color: $color-off-black;
  font-family: "Raleway", sans-serif;
  padding: 0 60px;
  @include sm() {
    padding: 0 20px;
  }
  flex-wrap: wrap;
  text-align: left;

  .projects-material-container {
    & > div:not(:first-child) {
      margin-bottom: 70px;
    }
  }

  .projects-project {
    margin: 0;
    font-size: 1.4em;
  }

  .projects-image {
    width: 100%;
  }

  .projects-info {
    display: flex;
    flex-direction: row;
    * {
      margin: 8px 0;
    }

    .projects-company {
      font-size: 0.9em;
      margin-left: 15px;
    }
    .projects-year {
      font-size: 0.9em;
      margin-right: 15px;
    }
  }
  .projects-content {
    font-size: 0.8em;
    font-weight: 300;
    margin-bottom: 0;
  }
  .projects-link {
    font-size: 0.75em;
    font-weight: 300;
    margin: 0;
    color: $color-vivid-sky-blue;
    &:hover {
      opacity: 0.8;
    }
  }
}
