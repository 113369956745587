@import "Styles/colors.scss";
@import "Styles/mediaQueries.scss";

.skills-container {
  background-color: $color-off-black;
  font-family: "Raleway", sans-serif;
  padding: 0 60px;
  padding-bottom: 50px;
  @include sm() {
    padding: 0 20px;
    padding-bottom: 50px;
  }

  .skills-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    h3 {
      margin: 0;
      font-weight: medium;
    }

    .skills-div {
      padding: 0 20px;
      padding-bottom: 20px;

      .skills-heading {
        color: $color-vivid-sky-blue;
        font-size: 1em;
        font-weight: medium;
        margin-bottom: 10px;
      }
    }
  }

  .icon {
    font-size: 46px;
    @include sm() {
      font-size: 30px;
    }
    color: white;
    &:hover {
      opacity: 0.5;
    }
    margin: 5px;
    margin-bottom: 8px;
    margin-top: 2px;
    text-decoration: none;
    vertical-align: top;
  }
  img {
    height: 46px;
    @include sm() {
      height: 30px;
    }
    &:hover {
      opacity: 0.5;
    }
    margin: 5px;
    filter: brightness(0) invert(1);
  }
}
