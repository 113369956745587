// .video-player {
//   height: 100% !important;
//   video {
//     object-fit: cover;
//   }
// }
video {
  object-fit: cover;
}

.video-div {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
}
