@import "Styles/mediaQueries.scss";

.landing-header {
  display: flex;
  // flex-basis: auto;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  .main-section-text {
    position: absolute;
    z-index: 2;
    @include sm() {
      bottom: 20px;
      right: 20px;
    }
    bottom: 40px;
    right: 60px;
    h1 {
      text-align: right;
      font-size: 4em;
      margin: 0;
      font-smooth: auto;
    }
    p {
      text-align: right;
      font-family: "Raleway", sans-serif;
      font-size: 1.15em;
      margin: 0;
      margin-left: 30px;
    }
    font-family: "Arca Major", sans-serif;
    text-align: left;
  }

  .landing-section {
    width: 100%;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;

    .empty-div {
      height: 100%;
      flex-shrink: 0;
    }
  }
}
