@import "Styles/colors.scss";
@import "Styles/mediaQueries.scss";

.section-heading {
  font-size: 1.6em;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
  margin-bottom: 16px;
}

.about-container {
  background-color: $color-rich-black;
  font-family: "Raleway", sans-serif;
  padding: 0 60px;
  padding-bottom: 50px;
  @include sm() {
    padding: 0 20px;
    padding-bottom: 50px;
  }
  flex-wrap: wrap;
  display: flex;

  .about-content {
    font-size: 0.9em;
    font-weight: 300;
  }
}
