@import "Styles/colors.scss";
@import "Styles/mediaQueries.scss";

.section-heading {
  font-size: 1.6em;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
}

.contact-container {
  background-color: $color-rich-black;
  font-family: "Raleway", sans-serif;
  padding: 0 60px;
  padding-bottom: 50px;
  @include sm() {
    padding: 0 20px;
    padding-bottom: 50px;
  }
  flex-wrap: wrap;
  display: flex;

  .contact-heading {
    font-size: 1.8em;
    margin: 0;
    margin-top: 110px;
  }
  .contact-content {
    font-size: 0.8em;
    font-weight: 400;
    color: $color-vivid-sky-blue;
    margin: 0;
  }
  .contact-link {
    font-weight: 400;
    font-size: 0.9em;
    color: $color-vivid-sky-blue;
    &:hover {
      color: $color-vivid-sky-blue-hover;
    }
  }

  .contact-explanation {
    font-size: 0.6em;
    font-weight: medium;
    color: grey;
    margin: 0;
    margin-top: 110px;
  }
}
